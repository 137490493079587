import "./sentHomeMessage.scss";
import { useModalState } from "@clipboard-health/ui-react";
import { IonButton, IonIcon } from "@ionic/react";
import { useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { CustomMessageTypes } from "@src/appV2/Chat/type";
import { environmentConfig } from "@src/appV2/environment";
import { CbhFeatureFlag, FeatureFlagVariants } from "@src/appV2/FeatureFlags";
import { UpdateEtaDialog } from "@src/appV2/Shifts/MyShiftUnavailable/lateForShift/UpdateEta/UpdateEtaDialog";
import { Shift } from "@src/appV2/Shifts/Shift/types";
import { useCheckAttendanceV1 } from "@src/appV2/UrgentShifts/api/useCheckAttendance.v1";
import { AttendanceConfirmationChannel } from "@src/appV2/UrgentShifts/constant";
import { Shift as OldShift } from "@src/lib/interface/src";
import { checkmarkCircleOutline } from "ionicons/icons";
import moment from "moment-timezone";
import { EveryMessage } from "sendbird-uikit";

export interface InputEtaMessageProps {
  shift: OldShift;
  message: Pick<EveryMessage, "messageId"> & Partial<{ message: string | null }>;
  urgentShiftsConfig: FeatureFlagVariants[CbhFeatureFlag.URGENT_SHIFTS_CONFIG];
}

function InputEtaMessage(props: InputEtaMessageProps) {
  const { shift, message, urgentShiftsConfig } = props;
  const { data: agent } = useAgentProfile();
  const modalState = useModalState();
  const maxEtaBoundInMinutes = urgentShiftsConfig?.checkAttendanceBoundsInMinutes?.[1];
  const shouldShowEtaMessageResponse = useCheckAttendanceV1({
    urgentShiftsServiceUrl: environmentConfig.REACT_APP_URGENT_SHIFTS_SERVICE_URL,
    shiftId: shift._id ?? "",
    shiftStart: shift.start ?? "",
    options: {
      cacheTime: 0,
    },
  });

  const { data, isSuccess } = shouldShowEtaMessageResponse;

  if (!shift.start || shift.deleted || !isSuccess || !data?.attendanceCheck) {
    return null;
  }

  return (
    <>
      <div className="fcm-message-container">
        <div className="fcm-avatar-container">
          <div>
            <img src="assets/logo/bunny.png" />
          </div>
        </div>
        <div className="fcm-message-body">
          <span>Clipboard Health</span>
          <div className="message-wrapper">
            <div>
              <div>
                Running late for your {moment(shift.start).tz(String(agent?.tmz)).format("MM/DD")}{" "}
                {moment(shift.start).tz(String(agent?.tmz)).format("h:mm A")} shift? Tap to update
                the facility with your ETA
              </div>
            </div>

            <div className="input-eta-btn-container">
              <IonButton
                size="default"
                shape="round"
                expand="block"
                color="primary"
                onClick={modalState.openModal}
              >
                {message.message === CustomMessageTypes.INPUT_ETA_SUBMITTED ? (
                  <IonIcon icon={checkmarkCircleOutline} />
                ) : (
                  "Update ETA"
                )}
              </IonButton>
            </div>
          </div>
        </div>
      </div>
      {/* FIXME: Mismatch between shift from legacy and appV2 defined Shift objects.
            Change the usages of this component to use appV2 Shift Object. */}
      {maxEtaBoundInMinutes ? (
        <UpdateEtaDialog
          shift={shift as unknown as Shift}
          modalState={modalState}
          sendbirdMessageId={message.messageId}
          channel={AttendanceConfirmationChannel.FACILITY_CHAT}
          maxEtaBoundInMinutes={maxEtaBoundInMinutes}
        />
      ) : null}
    </>
  );
}

export default InputEtaMessage;
